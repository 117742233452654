import styled, { css, keyframes } from 'styled-components';
import { colors } from '../theme/configs/baseVariable';
import { ProgressProps } from './ProgressBar';
import { SpinnerProps } from './Spinner';
import { FlatLoadingProps } from './FlatLoading';

const progressStyle = (props: ProgressProps) => css`
  width: ${props.progressBarSize}%;
  height: 5px;
  background: ${colors.primary};
`;

const progressStyleInverse = (props: ProgressProps) => css`
  width: ${100 - props.progressBarSize}%;
  height: 5px;
  background: ${colors.primaryLight};
`;

const progressStyleWrapper = css`
  width: 100%;
  height: 5px;
  display: inline-flex;
`;

export const Progress = styled('div')<ProgressProps>`
  ${progressStyle}
`;

export const ProgressInverse = styled('div')<ProgressProps>`
  ${progressStyleInverse}
`;

export const ProgressWrapper = styled('div')<ProgressProps>`
  ${progressStyleWrapper}
`;

const rotate = (props: SpinnerProps) => keyframes`
  0%,
  100% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']},
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33,
    2.125em 0em 0 0em ${colors[props.color || 'primary']}33,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}33,
    0em 2.125em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}33,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}80,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}B3;
  }
  12.125% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}B3,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']},
    2.125em 0em 0 0em ${colors[props.color || 'primary']}33,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}33,
    0em 2.125em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}33,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}80;
  }
  25% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}80,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}B3,
    2.125em 0em 0 0em ${colors[props.color || 'primary']},
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}33,
    0em 2.125em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}33,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33;
  }
  37.5% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}33,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}80,
    2.125em 0em 0 0em ${colors[props.color || 'primary']}B3,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']},
    0em 2.125em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}33,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33;
  }
  50% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}33,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33,
    2.125em 0em 0 0em ${colors[props.color || 'primary']}80,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}B3,
    0em 2.125em 0 0em ${colors[props.color || 'primary']},
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}33,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33;
  }
  62.125% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}33,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33,
    2.125em 0em 0 0em ${colors[props.color || 'primary']}33,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}80,
    0em 2.125em 0 0em ${colors[props.color || 'primary']}B3,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']},
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33;
  }
  75% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}33,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33,
    2.125em 0em 0 0em ${colors[props.color || 'primary']}33,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}33,
    0em 2.125em 0 0em ${colors[props.color || 'primary']}80,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}B3,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']},
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33;
  }
  87.5% {
    box-shadow: 0em -2.21em 0em 0em ${colors[props.color || 'primary']}33,
    1.53em -1.53em 0 0em ${colors[props.color || 'primary']}33,
    2.125em 0em 0 0em ${colors[props.color || 'primary']}33,
    1.4875em 1.4875em 0 0em ${colors[props.color || 'primary']}33,
    0em 2.125em 0 0em ${colors[props.color || 'primary']}33,
    -1.53em 1.53em 0 0em ${colors[props.color || 'primary']}80,
    -2.21em 0em 0 0em ${colors[props.color || 'primary']}B3,
    -1.53em -1.53em 0 0em ${colors[props.color || 'primary']};
  }
`;

const spinStyle = (props: SpinnerProps) => css`
  margin: ${props.size
    ? (Number(props.size.split('p')[0]) / 36) * 15 + 'px'
    : '15px'};
  font-size: ${props.size
    ? (Number(props.size.split('p')[0]) / 36) * 7 + 'px'
    : '7px'};
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: ${rotate} 1.1s infinite ease;
  animation: ${rotate} 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
`;

export const SpinnerStyle = styled('div')<SpinnerProps>`
  ${spinStyle}
`;

const wrapperStyling = (props: SpinnerProps) => css`
  padding-top: ${props.size
    ? (Number(props.size.split('p')[0]) / 36) * 20 + 'px'
    : '20px'};
  width: ${props.size} !important;
  height: ${props.size} !important;
`;

export const WrapperStyle = styled('div')<SpinnerProps>`
  ${wrapperStyling}
`;

const dot = (props: FlatLoadingProps) => keyframes`
    50% {
      transform: translateX(${
        props.size ? `calc(${props.size} * 0.676)` : '96px'
      });
    }
`;

const dots = (props: FlatLoadingProps) => keyframes`

    50% {
      transform: translateX(-21.8%);
    }
`;

const flatStyleWrapper = (props: FlatLoadingProps) => css`
  position: relative;
  width: ${props.size || '142px'};
  height: ${props.size ? `calc(${props.size} * 0.281)` : '40px'};
  background: inherit;
  .dot {
    position: absolute;
    width: 11.2%;
    height: 40%;
    left: 10.5%;
    background: ${props.color ? colors[props.color] : '#000'};
    border-radius: 50%;
    transform: translateX(0);
    animation: ${dot} 2.8s infinite;
  }
  .dots {
    transform: translateX(0);
    margin-left: 21.8%;
    animation: ${dots} 2.8s infinite;
    height: inherit;
    width: inherit;
    span {
      display: block;
      float: left;
      width: 11.2%;
      height: 40%;
      margin-left: 11.2%;
      background: ${props.color ? colors[props.color] : '#000'};
      border-radius: 50%;
    }
  }
`;

export const FlatWrapper = styled('div')<FlatLoadingProps>`
  ${flatStyleWrapper}
`;
