import * as React from 'react';
import { ImageStyleProps, ImageStyled } from './style';

export interface ImageProps
  extends React.HtmlHTMLAttributes<HTMLImageElement>,
    ImageStyleProps {
  /** Basic attr */
  id?: string;
  className?: string;
  src: string;
  alt?: string;
  onClick?: (param?: any) => void;
  style?: React.CSSProperties;
}

const Image: React.FC<ImageProps> = ({
  style,
  src,
  alt,
  ...props
}: ImageProps) => {
  return (
    <ImageStyled
      className={props.className}
      size={props.size}
      square={props.square}
      rounded={props.rounded}
      avatar={props.avatar}
      fluid={props.fluid}
      src={src}
      alt={alt}
      style={style}
      onClick={props.onClick}
    />
  );
};

Image.defaultProps = {
  alt: '',
};

export default Image;
