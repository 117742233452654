import * as React from 'react';
// import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltUp,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { InputBaseProps, InputBase, IconBase } from '../../styles';

export interface InputTextProps
  extends InputBaseProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  setData?: any;
  onClick?: any;
  disabledButton?: boolean;
}

const Input = styled('input')<InputTextProps>`
  ${InputBase};
  height: 40px;
  -webkit-appearance: none;
`;

const Button = styled('button')`
  -webkit-appearance: none;
  background: none;
  border: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin-right: -2px;
`;

const Icon = styled('div')<InputTextProps>`
  ${IconBase};
`;

const InputText: React.FunctionComponent<InputTextProps> & {
  defaultProps: Partial<InputTextProps>;
} = ({
  disabledButton,
  max,
  disabled,
  type,
  errors,
  validate,
  onClick,
  setData,
  icon,
  className,
  children,
  ...rest
}) => {
  // const [err, setErr] = useState(false);
  // const [valid, setValid] = useState(false);

  const inputChangeHandle = (data: any) => {
    // if (type === 'number') {
    //   setErr(!data.target.value || data.target.value === '' || Number(data.target.value) < 0 || (max ? Number(data.target.value) > max : Number(data.target.value) > 9999999999));
    // } else if (type === 'email') {
    //   setErr(!data.target.value || data.target.value === '' ||  !String(data.target.value).includes('.') || !String(data.target.value).includes('@'));
    // } else {
    //   setErr(!data.target.value || data.target.value === '');
    // }

    // if (type === 'email') {
    //   setValid(data.target.value && String(data.target.value).includes('.') && String(data.target.value).includes('@'));
    // } else {
    //   setValid(data.target.value);
    // }

    setData(data.target.value);
  };

  if (icon) {
    return (
      <div
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        className={className}
      >
        <Input
          errors={errors}
          disabled={disabled}
          type={type}
          max={max}
          validate={validate}
          onChange={e => inputChangeHandle(e)}
          rounded
          {...rest}
        />
        <Button>
          <Icon
            disabled={disabledButton}
            className={className || '' + `${disabledButton ? 'disabled' : ''}`}
          >
            <FontAwesomeIcon icon={faLongArrowAltUp} />
          </Icon>
        </Button>
      </div>
    );
  } else if (type === 'password') {
    const [isShowPassword, setShowPassword] = React.useState(false);
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Input
          className={className}
          type={isShowPassword ? 'text' : 'password'}
          max={max}
          errors={errors}
          disabled={disabled}
          validate={validate}
          onChange={e => inputChangeHandle(e)}
          {...rest}
        />
        <FontAwesomeIcon
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '16px',
            color: '#AAAAAA',
            cursor: 'pointer',
          }}
          onClick={() => setShowPassword(!isShowPassword)}
          icon={isShowPassword ? faEyeSlash : faEye}
        />
      </div>
    );
  } else {
    return (
      <Input
        className={className}
        type={type}
        max={max}
        errors={errors}
        disabled={disabled}
        validate={validate}
        onChange={e => inputChangeHandle(e)}
        {...rest}
      />
    );
  }
};

InputText.defaultProps = {
  errors: false,
  validate: false,
  small: false,
};

InputText.displayName = 'InputText';

export default InputText;
