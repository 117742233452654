import { css } from 'styled-components';

import inputThemes from './themes/input';
import { typeScale } from '../theme/configs/baseVariable';

export interface InputBaseProps {
  /** True if the input has errors. */
  errors?: boolean;
  validate?: boolean;
  small?: boolean;
  rounded?: boolean;
  icon?: boolean;
}

export interface TextareaBaseProps {
  /** True if the input has errors. */
  errors?: boolean;
  validate?: boolean;
  small?: boolean;
}

export const LabelBase = ({ small }: InputBaseProps) => {
  const scale = typeScale[small ? 'pagesubtitle' : 'pageTitle'];

  return css`
    display: block;
    margin-bottom: 10px;
    font-size: ${scale.fontSize}px;
    font-weight: ${scale.weight};
    line-height: ${scale.lineHeight}px;
    text-align: left;
    color: ${scale.color};
  `;
};

export const InputBase = ({
  errors,
  validate,
  small,
  rounded,
}: InputBaseProps) => {
  const type = errors ? 'error' : 'validate';
  const theme = inputThemes[validate || errors ? `${type}` : 'defaultTheme'];

  return css`
    display: block;
    width: ${small ? '64px' : '100%'};
    padding: 0px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${theme.backgroundColor};
    border: 1px solid ${theme.borderColor};
    ${rounded ? 'border-radius: 24px' : ''}
    font-size: 16px;
    line-height: ${small ? '24px' : '19px'};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: ${theme.mutedTextColor};
      font-style: italic;
    }

    &:disabled,
    &.disabled {
      background-color: ${theme.backgroundColorHover};
      color: ${theme.mutedTextColor};
    }

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: #fff;
        border: 2px solid ${theme.borderColorHover};
        padding: 13px;
      }

      &:active,
      &:focus {
        background-color: ${theme.backgroundColorActive};
        border: 2px solid ${theme.borderColorHover};
        padding: 0px 13px;
        box-shadow: 0 1px 1px 0 ${theme.focusOutlineColor},
          0 0 0 2px ${theme.focusOutlineColor};
      }
    }
  `;
};

export const TextareaBase = ({ errors, validate, small }: InputBaseProps) => {
  const type = errors ? 'error' : 'validate';
  const theme = inputThemes[validate || errors ? `${type}` : 'defaultTheme'];

  return css`
    display: block;
    width: ${small ? '200px' : '100%'};
    padding: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${theme.backgroundColor};
    border: 1px solid ${theme.borderColor};
    font-size: 16px;
    line-height: ${small ? '24px' : '19px'};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0);
    transition: all 0.3s ease;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: ${theme.mutedTextColor};
      font-style: italic;
    }

    &:disabled,
    &.disabled {
      background-color: ${theme.backgroundColorHover};
      color: ${theme.mutedTextColor};
    }

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: #fff;
        border: 2px solid ${theme.borderColorHover};
        padding: 13px;
      }

      &:active,
      &:focus {
        background-color: ${theme.backgroundColorActive};
        border: 2px solid ${theme.borderColorHover};
        padding: 13px;
        box-shadow: 0 1px 1px 0 ${theme.focusOutlineColor},
          0 0 0 2px ${theme.focusOutlineColor};
      }
    }
  `;
};

export const IconBase = ({ errors, validate }: InputBaseProps) => {
  const type = validate ? 'validate' : 'error';
  const theme = inputThemes[validate || errors ? `${type}` : 'defaultTheme'];

  return css`
    box-shadow: none;
    border: 1px solid #aaaaaa;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: relative;

    &::placeholder {
      color: ${theme.mutedTextColor};
      font-style: italic;
    }

    &:disabled,
    &.disabled {
      background-color: ${theme.backgroundColorHover};
      color: ${theme.mutedTextColor};
    }

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: #fff;
        border: 2px solid ${theme.borderColorHover};
        line-height: 28px;
        height: 28px;
        width: 28px;
      }

      &:active,
      &:focus {
        background-color: ${theme.backgroundColorActive};
        border: 2px solid ${theme.borderColorHover};
        line-height: 28px;
        height: 28px;
        width: 28px;
        box-shadow: none;
      }
    }
  `;
};
