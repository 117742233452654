import styled from 'styled-components';
import { colors } from '../../../theme/configs/baseVariable';
import { RadioButtonProps } from './RadioButton';

export const RadioButtonStyle = styled('label')<RadioButtonProps>`
  margin-right: 16px;
  margin-bottom: 16px;
  display: block;
  cursor: pointer;

  .prixa-options-input {
    display: none;
  }

  .prixa-options-label {
    padding-left: ${(props): string =>
      props.size ? `${props.size + 11}px` : '32px'};
    position: relative;
    ${(props): string | undefined =>
      props.fontSize && `font-size: ${props.fontSize}`}

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${(props): string => (props.size ? `${props.size}px` : '21px')};
      height: ${(props): string => (props.size ? `${props.size}px` : '21px')};
      border: 1px solid ${colors.disabled};
      border-radius: 50%;
      background-color: ${colors.white};
    }
  }

  &.checked .prixa-options-label {
    &::before {
      border: 2px solid ${(props): string => props.color || colors.success};
      width: ${(props): string =>
        props.size ? `${props.size - 2}px` : '19px'};
      height: ${(props): string =>
        props.size ? `${props.size - 2}px` : '19px'};
    }

    &::after {
      content: '';
      position: absolute;
      background-color: ${(props): string => props.color || colors.success};
      border-radius: 50%;
      width: ${(props): string =>
        props.size ? `${props.size - 6}px` : '15px'};
      height: ${(props): string =>
        props.size ? `${props.size - 6}px` : '15px'};
      top: 4px;
      left: 4px;
    }
  }
`;
