import styled from 'styled-components';

export const OptionsPrixa = styled('label')`
  &:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-table;
  }
`;

export const InputOptionsPrixa = styled('input')`
  opacity: 0;
  position: absolute;

  &:checked + div {
    background-color: #0046ab;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #0046ab;
  }
`;

export const DivOptionsPrixa = styled('div')`
  cursor: pointer;
  font-size: 16px;
  color: #4c4f54;
  padding: 11px 18px;
  font-weight: initial;
  border-radius: 21px;
  border: 1px solid #aaaaaa;
  white-space: nowrap;
  width: max-content;
`;
