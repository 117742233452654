import * as React from 'react';
import { CardStyled } from './style';

export interface CardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /** Basic attr */
  id?: string;
  className?: string;
  selection?: boolean;
  onClick?: (param?: any) => void;
  style?: React.CSSProperties;
  children?: any;
}

const Card: React.FC<CardProps> = ({
  style,
  children,
  ...props
}: CardProps) => {
  return (
    <CardStyled
      className={props.className}
      style={style}
      selection={props.selection}
    >
      {children}
    </CardStyled>
  );
};

Card.defaultProps = {};

export default Card;
