import { themeProps, TypeScale, Space } from '../../theme';

/**
 * Determines font sizes based on the text variable index.
 *
 * @param scale The size key.
 */

export function fontDimensions(
  scale: TypeScale,
  space?: Space,
  margin?: string,
  fontWeight?: number,
  fontSize?: string,
  color?: string,
  lineHeight?: string,
  fontStyle?: string
) {
  const match = themeProps.typeScale[scale];
  const spaceSize = !space ? '0px' : themeProps.space[space];

  return {
    fontSize: fontSize || `${match.fontSize}px`,
    lineHeight: lineHeight || `${match.lineHeight}px`,
    color: color || `${match.color}`,
    fontWeight: fontWeight || match.weight,
    fontStyle: fontStyle || `${match.isItalic ? 'italic' : ''}`,
    padding: spaceSize,
    margin: margin || '',
  };
}
