import styled, { css } from 'styled-components';
import { buttonType } from '../theme/configs/baseVariable';
import { BoxProps } from './Box';

const boxStyle = (props: BoxProps) => css`
  ${props.bordered
    ? 'border: solid 2px ' + buttonType[props.variant || 'default'].background
    : ''};
  ${!props.bordered
    ? 'background: ' + buttonType[props.variant || 'default'].background
    : ''};
  color: ${props.bordered
    ? buttonType[props.variant || 'default'].background
    : buttonType[props.variant || 'default'].color};
  padding: 12px;
  border-radius: 8pt;
`;

export const BoxWithStyle = styled('div')<BoxProps>`
  ${boxStyle}
`;
