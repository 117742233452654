import styled from 'styled-components';
import { colors } from '../theme/configs/baseVariable';
import { rgba } from '../theme/configs/utils';

export const AccordionContentStyled = styled('div')`
  display: none;
  position: relative;
  padding: 16px;
  cursor: auto;
  background-color: ${colors.white};
  border: 1px solid ${colors.disabled};
  font-size: 14px

  &:before {
    content: '';
    display: block;

    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    height: 1px;

    border-bottom: 1px solid ${colors.disabled};
    margin: 0 16px;
  }
`;

export const AccordionTitleStyled = styled('span')`
  color: ${colors.primary};
  flex: 1;
  font-weight: 700;
  margin-right: 8px;
`;

export const AccordionHeaderStyled = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 16px;

  border-radius: 5px;
  border: 1px solid ${colors.disabled};
  background-color: ${colors.white};
  box-shadow: 0 1px 1px 0 rgba(${colors.black}, 0);
  outline: none;

  font-size: 14px
  line-height: 14px;

  .icon {
    color: ${colors.disabled};
  }
`;

interface AccordionStyleProps {
  danger?: boolean;
  success?: boolean;
  disabled?: boolean;
  active?: boolean;
}

export const AccordionStyled = styled('div')<AccordionStyleProps>`

  ${props =>
    props.active &&
    `${AccordionHeaderStyled}{
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: none;
    }
    ${AccordionContentStyled} {
      display: block;

      border-top: none;
      border-radius: 0px 0px 5px 5px;
    }
  `}

  ${props =>
    props.danger &&
    `
    ${AccordionHeaderStyled}{
      background-color: ${colors.danger};
      border-color: ${colors.danger};
      color: ${colors.white};
    }
    

    ${AccordionTitleStyled}{
      color: ${colors.white}
    }

    ${AccordionContentStyled} {
      background-color: ${colors.danger};
      border-color: ${colors.danger};
      color: ${colors.white}

      &:before {
        border-color: ${colors.white};
      }
    }
  `}

  ${props =>
    props.success &&
    `
      ${AccordionHeaderStyled}{
        background-color: ${colors.success};
        border-color: ${colors.success};
        color: ${colors.white};
      }

      ${AccordionTitleStyled} {
        color: ${colors.white};
      }

      ${AccordionContentStyled} {
        background-color: ${colors.success};
        border-color: ${colors.success};
        color: ${colors.white}

        &:before {
          border-color: ${colors.white};
        }
      }
    `}

  ${props =>
    props.disabled &&
    `
    ${AccordionHeaderStyled}{
      cursor: not-allowed;
      background-color: ${rgba(colors.trueBlack, 0.05)};
      color: ${colors.disabled};
      border-radius: 5px;
    }

    ${AccordionTitleStyled}{
      color: ${colors.disabled};
    }
  `}
  
`;
