import styled from 'styled-components';
import { colors } from '../../../theme/configs/baseVariable';
import { rgba } from '../../../theme/configs/utils';

export interface DropdownStyleProps {
  active?: boolean;
  disabled?: boolean;
}

export const DropdownStyled = styled('div')<DropdownStyleProps>`
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 16px;
  height: 14px;

  border-radius: 5px;
  border: 1px solid ${colors.disabled};
  background-color: ${colors.white};
  box-shadow: 0 1px 1px 0 rgba(${colors.black}, 0);
  outline: none;

  font-size: 14px
  line-height: 14px;

  .text {
    color: ${colors.dark};
    flex: 1;

    &.placeholder {
      color: ${colors.disabled};
      font-style: italic;
    }
  }

  input {
    display: none;
  }

  
  ${props =>
    props.active &&
    !props.disabled &&
    `
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: none;
    `}

  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
      background-color: ${rgba(colors.trueBlack, 0.05)};
      color: ${colors.disabled};
  `}

`;

interface DropdownMenuProps {
  visible?: boolean;
}

export const DropdownMenuStyled = styled('div')<DropdownMenuProps>`
  display: none;
  position: absolute;

  ${props =>
    props.visible &&
    `
      display: block;
      top: 46px;
      left: -1px;
      right: -1px;

      background-color: ${colors.light};
      border: 1px solid ${colors.disabled};
      border-top: none;
      border-radius: 0px 0px 5px 5px;

      z-index: 10;
  `}
`;

interface DropdownItemProps {
  disabled?: boolean;
}

export const DropdownItemStyled = styled('div')<DropdownItemProps>`
  position: relative;
  padding: 16px;

  &:hover {
    background-color: ${rgba(colors.trueBlack, 0.05)};
  }

  &:before {
    content: '';
    display: block;

    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    height: 1px;

    border-bottom: 1px solid ${colors.disabled};
    margin: 0 16px;
  }

  ${props =>
    props.disabled &&
    `
      cursor: not-allowed;
      color: ${colors.disabled};
  `}
`;
