import styled from 'styled-components';
import { colors } from '../theme/configs/baseVariable';
import { rgba } from '../theme/configs/utils';
import { CardProps } from './Card';

export const CardStyled = styled('div')<CardProps>`
  position: relative;
  top: 0;

  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 ${rgba(colors.dark, 0.16)};
  background-color: ${colors.white};

  transition: top ease 0.3s;

  ${props =>
    props.selection &&
    `
      &:hover {
        cursor: pointer;
        top: -2px;
      }
    `}
`;
