import * as React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../theme/configs/baseVariable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as iconList from '@fortawesome/free-solid-svg-icons';

export interface IconProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /** Basic attr */
  id?: string;
  children?: any;
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  type: string;
  color?: string;
  width?: string;
  spin?: boolean;
}

const iconCSS = (props: IconProps) => css`
  width: ${props.width} !important;
  color: ${colors[props.color || 'primary']};
`;

const IconStyled = styled(FontAwesomeIcon)<IconProps>`
  ${iconCSS}
`;

const Icon: React.SFC<IconProps> = (props: IconProps): React.ReactElement => (
  <div style={{ display: 'flex' }}>
    <IconStyled
      className={props.className}
      style={props.style}
      color={props.color}
      width={props.width}
      type={props.type}
      icon={iconList[props.type]}
      spin={props.spin}
    />
    {props.children}
  </div>
);

Icon.defaultProps = {
  color: 'primary',
  type: 'faTimes',
};

Icon.displayName = 'Icon';

export default Icon;
