import * as React from 'react';

import { styledWrapper as styled } from '../../theme/configs/baseComponents';
import { TypeScale } from '../../theme';

import { fontDimensions } from '../configs/fontDimensions';
import Typography, { TypographyProps } from './Typography';

export interface ParagraphProps extends TypographyProps {
  /** Basic Attr */
  children?: any;
  className?: string;
  style?: React.CSSProperties;
  /** Custom Attr */
  scale?: TypeScale;
  margin?: string | undefined;
  fontWeight?: number | undefined;
  fontSize?: string | undefined;
  color?: string | undefined;
  lineHeight?: string | undefined;
  fontStyle?: string | undefined;
}

const StyledParagraph = styled(Typography)<ParagraphProps>`
  word-break: break-word;
  ${props => props.margin && `margin: ${props.margin}`}
  margin-top: 0px;
`;

const Paragraph: React.FunctionComponent<ParagraphProps> = (
  props: ParagraphProps
): React.ReactElement => (
  <StyledParagraph
    as="p"
    {...fontDimensions(
      props.scale || 'content',
      undefined,
      props.margin || undefined,
      props.fontWeight || undefined,
      props.fontSize || undefined,
      props.color || undefined,
      props.lineHeight || undefined,
      props.fontStyle || undefined
    )}
    style={props.style}
    className={props.className}
  >
    {props.children}
  </StyledParagraph>
);

Paragraph.defaultProps = {
  scale: 'content',
};

Paragraph.displayName = 'Paragraph';

export default Paragraph;
