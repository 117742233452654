import { css } from 'styled-components';
import { buttonType, buttonSizes } from '../../theme/configs/baseVariable';
import { ButtonBaseProps } from './utils';

export const ButtonSize = (props: ButtonBaseProps) => css`
  height: ${
    buttonSizes[
      props.size && props.variant !== 'outline' ? props.size : 'option'
    ].height
  }
  ${
    props.size === 'option'
      ? undefined
      : 'width: ' + buttonSizes[props.size || 'base'].width
  }

  ${() => {
    if (props.size === 'option') {
      return '';
    } else if (props.width) {
      return `width: ${props.width};`;
    } else {
      return `width: ${buttonSizes[props.size || 'base'].width};`;
    }
  }}

  padding: ${() => {
    if (props.padding) {
      return `${props.padding}; width: auto; height: auto`;
    } else if (props.variant === 'outline') {
      return `7px 8px;`;
    } else if (props.size === 'option') {
      return `14px 24px;`;
    } else {
      return `0px;`;
    }
  }}
  border-radius: ${buttonSizes[props.size || 'base'].rounded}
  font-weight: 700;
  font-size: ${buttonSizes[props.size || 'base'].fontSize};
`;

export const ButtonType = (props: ButtonBaseProps) => css`
  border: none;
  ${props.variant === 'outline'
    ? 'background-color: transparent; border: solid 2pt;' +
      `${props.color || buttonType[props.variant || 'default'].color}`
    : 'background-color: ' +
      `${props.color || buttonType[props.variant || 'default'].background}`};
  color: ${`${props.color || buttonType[props.variant || 'default'].color}`};
  ${props.color && props.variant !== 'outline' ? 'color: white;' : ''}
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      ${!buttonType[props.variant || 'default'].hover
        ? 'opacity: ' + buttonType[props.variant || 'default'].opacity
        : 'background-color: ' + buttonType[props.variant || 'default'].hover};
      outline: none;
    }
    &:focus,
    &.focus {
      outline: none;
    }
  }
  &:disabled,
  &.disabled {
    ${props.variant === 'outline'
      ? 'border-color: ' + buttonType.disabled.color
      : 'background-color: ' + buttonType.disabled.background};
    color: ${buttonType.disabled.color};
    cursor: default;
  }
`;

// export const ButtonCustomized = (props: ButtonBaseProps) => css`
// `;
// ${props.width ? 'width: ' + props.width+';' : ''}
// ${props.padding ? 'padding: ' + props.padding +';' : ''}
// ${props.variant === 'outline' && props.color ? 'border-color: ' + props.color+'; color: ' + props.color+';' : ''}
// ${props.variant !== 'outline' && props.color ? 'background-color: ' + props.color+';' : ''}
