import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const ToastContainerStyle = styled(ToastContainer)`
  @media (max-width: 480px) {
    width: 94vw !important;
    left: 3vw !important;
    top: 3vw !important;
    margin-bottom: 3vw !important;
  }
  & > .prixa-design-kit-toast {
    padding: 12px;
    border-radius: 8pt;
    min-height: max-content;
    margin-bottom: 12px;
    & > .Toastify__toast-body {
      color: #ffffff;
      /* color: #4C4F54; */
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      margin: 0px;
    }
    &.Toastify__toast--default > .Toastify__toast-body {
      color: #4c4f54;
    }
    &.Toastify__toast--info {
      background-color: #55b9e4;
    }
    &.Toastify__toast--success {
      background-color: #44cd90;
    }
    &.Toastify__toast--warning {
      background-color: #ffd557;
    }
    &.Toastify__toast--error {
      background-color: #ff6e5d;
    }
  }
`;

export default ToastContainerStyle;
