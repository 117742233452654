import { default as TextInit } from './components/Text';
import { default as ParagraphInit } from './components/Paragraph';
import { default as ListInit } from './components/List';
import { default as LinkInit } from './components/Link';

export const Text = TextInit;
export const Paragraph = ParagraphInit;
export const List = ListInit;
export const Link = LinkInit;

export default {
  Paragraph,
  Text,
  List,
  Link,
};
