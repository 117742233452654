import * as React from 'react';
import styled from 'styled-components';

import { InputBaseProps, LabelBase } from '../../styles';

export interface FormLabelProps
  extends InputBaseProps,
    React.LabelHTMLAttributes<HTMLLabelElement> {
  errors?: boolean;
  small?: boolean;
  validate?: boolean;
  className?: string;
  children?: any;
}

const Label = styled('label')`
  ${LabelBase};
`;

const FormLabel: React.FC<FormLabelProps> = (
  props: FormLabelProps,
  { ...rest }
) => {
  return (
    <Label
      className={props.className}
      errors={props.errors}
      small={props.small}
      validate={props.validate}
      {...rest}
    >
      {props.children}
    </Label>
  );
};

FormLabel.defaultProps = {
  errors: false,
};

FormLabel.displayName = 'FormLabel';

export default FormLabel;
