import * as React from 'react';

import { styledWrapper as styled } from '../../theme/configs/baseComponents';
import { TypeScale } from '../../theme';

import { fontDimensions } from '../configs/fontDimensions';
import Typography, { TypographyProps } from './Typography';

export interface ListProps
  extends TypographyProps,
    React.LiHTMLAttributes<HTMLLIElement> {
  /** Basic Attr */
  children?: any;
  className?: string;
  style?: React.CSSProperties;
  /** Custom Attr */
  scale?: TypeScale;
  type?: string;
  margin?: string | undefined;
  fontWeight?: number | undefined;
  fontSize?: string | undefined;
  color?: string | undefined;
  lineHeight?: string | undefined;
  fontStyle?: string | undefined;
}

const StyledList = styled(Typography)<ListProps>`
  word-break: break-word;
  margin-top: 0px;
`;

const List: React.FunctionComponent<ListProps> = (
  props: ListProps
): React.ReactElement => {
  if (props.type == 'ol') {
    return (
      <StyledList
        as="ol"
        {...fontDimensions(
          props.scale || 'content',
          undefined,
          props.margin || undefined,
          props.fontWeight || undefined,
          props.fontSize || undefined,
          props.color || undefined,
          props.lineHeight || undefined,
          props.fontStyle || undefined
        )}
        style={props.style}
        className={props.className}
      >
        {props.children}
      </StyledList>
    );
  } else {
    return (
      <StyledList
        as="ul"
        {...fontDimensions(
          props.scale || 'content',
          undefined,
          props.margin || undefined,
          props.fontWeight || undefined,
          props.fontSize || undefined,
          props.color || undefined,
          props.lineHeight || undefined,
          props.fontStyle || undefined
        )}
        style={props.style}
        className={props.className}
      >
        {props.children}
      </StyledList>
    );
  }
};

List.defaultProps = {
  scale: 'content',
  type: 'ul',
};

List.displayName = 'List';

export default List;
