import styled, { css } from 'styled-components';
import { baseSize } from '../theme/configs/baseVariable';

export interface ImageStyleProps {
  square?: boolean;
  rounded?: boolean;
  avatar?: boolean;
  size?: 'micro' | 'tiny' | 'small' | 'base' | 'large' | 'xLarge';
  fluid?: boolean;
}

const imageSizeMultiplier = (size: number) => {
  return size * 5;
};

export const ImageStyled = styled('img')<ImageStyleProps>`

  ${props =>
    props.size &&
    css`
      height: ${imageSizeMultiplier(baseSize[props.size])}px;
    `}

  ${props =>
    props.fluid &&
    css`
      width: 100%;
    `}

  ${props =>
    props.rounded &&
    css`
      border-radius: 8px;
    `}

  ${props =>
    props.square &&
    props.size &&
    css`
      width: ${imageSizeMultiplier(baseSize[props.size])}px;
    `}

  ${props =>
    props.avatar &&
    props.size &&
    css`
      width: ${imageSizeMultiplier(baseSize[props.size])}px;
      border-radius: 50%;
    `}
`;
