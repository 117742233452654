import { colors } from '../../theme/configs/baseVariable';

const defaultTheme = {
  backgroundColor: colors.light,
  backgroundColorHover: '#0000000d',
  backgroundColorActive: colors.light,
  textColor: colors.dark,
  mutedTextColor: colors.disabled,
  borderColor: colors.disabled,
  borderColorHover: colors.primary,
  focusOutlineColor: colors.primary,
};

const error = {
  ...defaultTheme,
  borderColor: colors.danger,
  borderColorHover: colors.danger,
  focusOutlineColor: colors.danger,
};

const validate = {
  ...defaultTheme,
  borderColor: colors.success,
  borderColorHover: colors.success,
  focusOutlineColor: colors.success,
};

const inputThemes = {
  defaultTheme,
  error,
  validate,
};

export default inputThemes;
