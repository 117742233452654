import * as React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../theme/configs/baseVariable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as iconList from '@fortawesome/free-solid-svg-icons';

export interface IconSolidProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /** Basic attr */
  id?: string;
  children?: any;
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  backgroundColor?: string;
  backgroundSize?: string;
  margin?: string;
  type: string;
  color?: string;
  spin?: boolean;
  width?: string;
}

const iconBackgroundCSS = (props: IconSolidProps) => css`
  width: ${props.backgroundSize};
  height: ${props.backgroundSize};
  background: ${colors[props.backgroundColor || 'light']};
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconBackgroundStyled = styled('div')<IconSolidProps>`
  ${iconBackgroundCSS}
`;

const iconCSS = (props: IconSolidProps) => css`
  margin: ${props.margin};
  width: ${props.width} !important;
  color: ${colors[props.color || 'primary']};
`;

const IconStyled = styled(FontAwesomeIcon)<IconSolidProps>`
  ${iconCSS}
`;

const IconSolid: React.SFC<IconSolidProps> = (
  props: IconSolidProps
): React.ReactElement => (
  <div style={{ display: 'flex' }}>
    <IconBackgroundStyled
      backgroundColor={props.backgroundColor}
      backgroundSize={props.backgroundSize}
      margin={props.margin}
      type={props.type}
    >
      <IconStyled
        className={props.className}
        style={props.style}
        color={props.color}
        spin={props.spin}
        width={props.width}
        type={props.type}
        icon={iconList[props.type]}
      />
    </IconBackgroundStyled>
    {props.children}
  </div>
);

IconSolid.defaultProps = {
  backgroundSize: '18px',
  backgroundColor: 'primary',
  color: 'white',
  width: '8px',
  margin: '0px',
};

IconSolid.displayName = 'IconSolid';

export default IconSolid;
