import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  CheckboxContainerStyled,
  CheckboxInputStyled,
  CheckboxStyled,
  CheckboxLabelStyled,
  CheckboxStyleProps,
} from './style';

export interface CheckboxProps
  extends React.HtmlHTMLAttributes<HTMLInputElement>,
    CheckboxStyleProps {
  /** Basic attr */
  label?: any;
  checked?: boolean;
  value?: any;
  onChange?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  value,
  onChange,
  ...rest
}: CheckboxProps) => {
  return (
    <CheckboxContainerStyled>
      <CheckboxInputStyled
        type="checkbox"
        name="checkbox"
        onChange={onChange ? () => onChange() : () => ''}
        value={value}
        {...rest}
      />
      <CheckboxStyled>
        <FontAwesomeIcon icon={faCheck} />
      </CheckboxStyled>
      <CheckboxLabelStyled>{label}</CheckboxLabelStyled>
    </CheckboxContainerStyled>
  );
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
