import * as React from 'react';
import { WrapperStyle, SpinnerStyle } from './style';

export interface SpinnerProps extends React.HtmlHTMLAttributes<HTMLElement> {
  /** Basic attr */
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  size?: string;
  color?: string;
}

const Spinner: React.SFC<SpinnerProps> = (props: SpinnerProps) => {
  return (
    <WrapperStyle
      size={props.size}
      color={props.color}
      className={props.className}
      style={props.style}
    >
      <SpinnerStyle size={props.size} color={props.color} style={props.style} />
    </WrapperStyle>
  );
};

Spinner.defaultProps = {
  color: 'primary',
  size: '36px',
};

Spinner.displayName = 'Spinner';

export default Spinner;
