import * as React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors, space, fonts, typeScale } from './configs/baseVariable';

export const themeProps = {
  colors,
  space,
  fonts,
  typeScale,
};

export interface BaseProps {
  /** Basic attr */
  children?: any;
}

const PrixaRoot = styled.section`
  font-family: ${fonts.sansSerif};
  background-color: ${colors.background || '#fff'};
`;

export const StyleReset: React.FC = (props: BaseProps) => {
  return <div>{props.children}</div>;
};

export const PrixaStyle: React.FC = (props: BaseProps) => {
  return (
    <ThemeProvider theme={themeProps}>
      <PrixaRoot>{props.children}</PrixaRoot>
    </ThemeProvider>
  );
};

export type Color = keyof typeof themeProps['colors'];
export type Space = keyof typeof themeProps['space'];
export type TypeScale = keyof typeof themeProps['typeScale'];
