import * as React from 'react';
import { RadioButtonStyle } from './style';

export interface RadioButtonProps {
  name: string;
  selected: boolean;
  value: any;
  onChange: (value: any) => void;
  text: string;
  size?: number;
  fontSize?: string;
  color?: string;
  style?: object;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  selected,
  value,
  onChange,
  text,
  size,
  fontSize,
  color,
  style,
}: RadioButtonProps): React.ReactElement => {
  return (
    <RadioButtonStyle
      className={selected ? 'checked' : ''}
      name={name}
      selected={selected}
      value={value}
      onChange={onChange}
      text={text}
      size={size}
      fontSize={fontSize}
      color={color}
    >
      <input
        className="prixa-options-input"
        type="radio"
        name={name}
        checked={selected}
        onChange={onChange}
        value={value}
      />
      <span className="prixa-options-label" style={style}>
        {text}
      </span>
    </RadioButtonStyle>
  );
};

export default RadioButton;
