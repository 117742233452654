import styled from 'styled-components';
import { colors } from '../../../theme/configs/baseVariable';

export interface CheckboxStyleProps {
  label?: any;
  checked?: boolean;
  value?: any;
  onChange?: any;
}

export const CheckboxContainerStyled = styled('label')`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const CheckboxInputStyled = styled('input')<CheckboxStyleProps>`
  opacity: 0;
  position: absolute;

  &:checked + div {
    background-color: #44cd90;
    border: 1px solid #44cd90;
  }

  &:checked + div svg {
    opacity: 1;
  }
`;

export const CheckboxStyled = styled('div')<CheckboxStyleProps>`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid ${colors.disabled};
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${colors.white};
    opacity: 0;
    padding: 10px;
  }
`;

export const CheckboxLabelStyled = styled('span')`
  font-size: 16px;
  color: ${colors.dark};
  line-height: 24px;
  cursor: pointer;
`;
