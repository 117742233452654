import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import {
  DropdownStyled,
  DropdownStyleProps,
  DropdownItemStyled,
  DropdownMenuStyled,
} from './style';

export interface DropdownItem {
  text?: string;
  value?: any;
  disabled?: boolean;
}

export interface DropdownProps
  extends React.HtmlHTMLAttributes<HTMLSelectElement>,
    DropdownStyleProps {
  /** Basic attr */
  id?: string;
  className?: string;
  name?: string;
  value?: any;
  onClick?: (param?: any) => void;
  onSelect?: (param?: any) => void;
  style?: React.CSSProperties;
  options: Array<DropdownItem>;
  placeholder?: string;
  noResultMessage?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  style,
  name,
  options,
  ...props
}: DropdownProps) => {
  const [{ value, text }, setValue] = React.useState<DropdownItem>({
    value: '',
    text: props.placeholder,
  });
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  const selectItem = (selectedItem: DropdownItem) => {
    setValue(selectedItem);
    setShowOptions(false);
    if (props.onSelect) props.onSelect(selectedItem);
  };

  const clickedDropdown = () => {
    if (!props.disabled) setShowOptions(!showOptions);
  };

  // ---------- Start of Dropdown Item ----------
  let noResultMessage = 'No options found.';
  if (props.noResultMessage) noResultMessage = props.noResultMessage;

  const DropdownItems =
    options.length > 0 ? (
      options.map(({ value, text, disabled }) => (
        <DropdownItemStyled
          key={value}
          disabled={disabled}
          onClick={() => selectItem({ value, text })}
        >
          {text}
        </DropdownItemStyled>
      ))
    ) : (
      <DropdownItemStyled disabled>{noResultMessage}</DropdownItemStyled>
    );
  // ---------- End of Dropdown Item ----------

  return (
    <DropdownStyled
      className={props.className}
      active={showOptions}
      disabled={props.disabled}
      style={style}
      onClick={clickedDropdown}
    >
      <input name={name} value={value} readOnly />
      <div className={`text ${value ? '' : 'placeholder'}`}>{text}</div>
      <FontAwesomeIcon icon={faChevronDown} />
      <DropdownMenuStyled visible={showOptions}>
        {DropdownItems}
      </DropdownMenuStyled>
    </DropdownStyled>
  );
};

Dropdown.defaultProps = {
  options: [],
};

export default Dropdown;
