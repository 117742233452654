/** Color pallette. */
export const colors = {
  text: '#0146AB',
  background: '#FFFFFF',
  primary: '#0146AB',
  secondary: '#55B9E4',
  success: '#44CD90',
  danger: '#FF6E5D',
  disabled: '#AAAAAA',
  dark: '#4C4F54',
  black: '#4C4F54',
  trueBlack: '#000000',
  light: '#FFFFFF',
  gray: '#EEEEEE',
  white: '#FFFFFF',
  primaryLight: '#A5BDE1',
  secondaryLight: '#C3E6F6',
};

export const systemFonts =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";

export const fonts = {
  system: systemFonts,
  sansSerif: `'Roboto', sans-serif, ${systemFonts}`,
  monospace:
    "'SF Mono', Inconsolata, Menlo, Monaco, Consolas, 'Courier New', Courier, monospace;",
};

export const baseSize = {
  none: 0,
  micro: 4,
  tiny: 8,
  small: 16,
  base: 24,
  large: 40,
  xLarge: 64,
};

/** Space values (in px) mapped by size designators */
export const space = {
  // All
  none: '0px',
  micro: '4px',
  tiny: '8px',
  small: '16px',
  base: '24px',
  large: '40px',
  xLarge: '64px',
  // Left Right
  microX: '0px 4px',
  tinyX: '0px 8px',
  smallX: '0px 16px',
  baseX: '0px 24px',
  largeX: '0px 40px',
  xLargeX: '0px 64px',
  // Top Bottom
  microY: '4px 0px',
  tinyY: '8px 0px',
  smallY: '16px 0px',
  baseY: '24px 0px',
  largeY: '40px 0px',
  xLargeY: '64px 0px',
  // Top
  microT: '4px 0px 0px 0px',
  tinyT: '8px 0px 0px 0px',
  smallT: '16px 0px 0px 0px',
  baseT: '24px 0px 0px 0px',
  largeT: '40px 0px 0px 0px',
  xLargeT: '64px 0px 0px 0px',
  // Right
  microR: '0px 4px 0px 0px',
  tinyR: '0px 8px 0px 0px',
  smallR: '0px 16px 0px 0px',
  baseR: '0px 24px 0px 0px',
  largeR: '0px 40px 0px 0px',
  xLargeR: '0px 64px 0px 0px',
  // Bottom
  microB: '0px 0px 4px 0px',
  tinyB: '0px 0px 8px 0px',
  smallB: '0px 0px 16px 0px',
  baseB: '0px 0px 24px 0px',
  largeB: '0px 0px 40px 0px',
  xLargeB: '0px 0px 64px 0px',
  // Left
  microL: '0px 0px 0px 4px',
  tinyL: '0px 0px 0px 8px',
  smallL: '0px 0px 0px 16px',
  baseL: '0px 0px 0px 24px',
  largeL: '0px 0px 0px 40px',
  xLargeL: '0px 0px 0px 64px',
};

/** Typography scale values (in pixels) mapped by style tokens. */
export const typeScale = {
  body: {
    fontSize: 14,
    lineHeight: 21,
    isItalic: false,
    color: colors.dark,
    weight: 400,
  },
  heroTitle: {
    fontSize: 28,
    lineHeight: 32,
    isItalic: false,
    color: colors.primary,
    weight: 700,
  },
  question: {
    fontSize: 24,
    lineHeight: 28,
    isItalic: false,
    color: colors.primary,
    weight: 700,
  },
  questionLink: {
    fontSize: 12,
    lineHeight: 24,
    isItalic: false,
    color: colors.success,
    weight: 900,
  },
  feedbackLink: {
    fontSize: 12,
    lineHeight: 24,
    isItalic: false,
    color: colors.secondary,
    weight: 900,
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: 18,
    isItalic: false,
    color: colors.primary,
    weight: 700,
  },
  headerSubtitle: {
    fontSize: 12,
    lineHeight: 18,
    isItalic: false,
    color: colors.dark,
    weight: 700,
  },
  pageTitle: {
    fontSize: 16,
    lineHeight: 24,
    isItalic: false,
    color: colors.dark,
    weight: 700,
  },
  pagesubtitle: {
    fontSize: 12,
    lineHeight: 24,
    isItalic: false,
    color: colors.primary,
    weight: 900,
  },
  questionLink2: {
    fontSize: 14,
    lineHeight: 21,
    isItalic: false,
    color: colors.success,
    weight: 700,
  },
  feedbackLink2: {
    fontSize: 14,
    lineHeight: 21,
    isItalic: false,
    color: colors.secondary,
    weight: 700,
  },
  content: {
    fontSize: 14,
    lineHeight: 21,
    isItalic: false,
    color: colors.dark,
    weight: 400,
  },
  caption: {
    fontSize: 12,
    lineHeight: 14,
    isItalic: true,
    color: colors.primary,
    weight: 400,
  },
  errorMessage: {
    fontSize: 12,
    lineHeight: 14,
    isItalic: true,
    color: colors.danger,
    weight: 400,
  },
};

/** Button typed. */
export const buttonSizes = {
  tiny: {
    height: '36px',
    width: '60px',
    rounded: '18pt',
    fontSize: '14px',
  },
  small: {
    height: '36px',
    width: '120px',
    rounded: '18pt',
    fontSize: '14px',
  },
  base: {
    height: '48px',
    width: '120px',
    rounded: '24pt',
    fontSize: '16px',
  },
  large: {
    height: '36px',
    width: '200px',
    rounded: '18pt',
    fontSize: '14px',
  },
  xLarge: {
    height: '48px',
    width: '180px',
    rounded: '24pt',
    fontSize: '16px',
  },
  option: {
    height: 'max-content',
    width: '180px',
    rounded: '24pt',
    fontSize: '16px',
  },
  full: {
    height: '64px',
    width: '100%',
    rounded: '0px',
    fontSize: '16px',
  },
  half: {
    height: '64px',
    width: '50%',
    rounded: '0px',
    fontSize: '16px',
  },
};

export const buttonType = {
  default: {
    color: colors.light,
    background: colors.primary,
    opacity: '100%',
    hover: colors.primaryLight,
  },
  outline: {
    color: colors.primary,
    background: colors.background,
    opacity: '60%',
    hover: undefined,
  },
  outlineWhite: {
    color: colors.light,
    background: colors.primary,
    opacity: '60%',
    hover: undefined,
  },
  primary: {
    color: colors.light,
    background: colors.primary,
    opacity: '100%',
    hover: colors.primaryLight,
  },
  disabled: {
    color: colors.light,
    background: colors.disabled,
    opacity: '60%',
    hover: undefined,
  },
  secondary: {
    color: colors.light,
    background: colors.secondary,
    opacity: '100%',
    hover: colors.secondaryLight,
  },
  success: {
    color: colors.light,
    background: colors.success,
    opacity: '60%',
    hover: undefined,
  },
  danger: {
    color: colors.light,
    background: colors.danger,
    opacity: '60%',
    hover: undefined,
  },
  gray: {
    color: colors.dark,
    background: colors.gray,
    opacity: '60%',
    hover: undefined,
  },
};
