import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as iconList from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.min.css';
import ToastContainerStyle from './style';

export interface ToastProps {
  /** Basic attr */
  children?: any;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  /** Custom attr */
  variant?: string;
  timeout?: number;
  message: string;
  show: boolean;
}

const Toast: React.FunctionComponent<ToastProps> = (
  props: ToastProps
): React.ReactElement => {
  const baseOption: object = {
    position: 'top-center',
    autoClose: props.timeout || 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  function notify() {
    if (props.variant === 'info' || props.variant === 'secondary') {
      toast.info(
        (
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              className="margin-tinyR"
              style={{ fontSize: '18px' }}
              icon={iconList.faInfoCircle}
            />
            {props.message}
          </div>
        ) || '',
        {
          ...baseOption,
          className: 'prixa-design-kit-toast toast-is-info',
        }
      );
    } else if (props.variant === 'success') {
      toast.success(
        (
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              className="margin-tinyR"
              style={{ fontSize: '18px' }}
              icon={iconList.faCheckCircle}
            />
            {props.message}
          </div>
        ) || '',
        {
          ...baseOption,
          className: 'prixa-design-kit-toast toast-is-success',
        }
      );
    } else if (props.variant === 'warning' || props.variant === 'warn') {
      toast.warn(
        (
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              className="margin-tinyR"
              style={{ fontSize: '18px' }}
              icon={iconList.faExclamationCircle}
            />
            {props.message}
          </div>
        ) || '',
        {
          ...baseOption,
          className: 'prixa-design-kit-toast toast-is-warning',
        }
      );
    } else if (props.variant === 'error' || props.variant === 'danger') {
      toast.error(
        (
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              className="margin-tinyR"
              style={{ fontSize: '18px' }}
              icon={iconList.faTimesCircle}
            />
            {props.message}
          </div>
        ) || '',
        {
          ...baseOption,
          className: 'prixa-design-kit-toast toast-is-error',
        }
      );
    } else {
      toast(
        (
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              className="margin-tinyR"
              style={{ fontSize: '18px' }}
              icon={iconList.faTimes}
            />
            {props.message}
          </div>
        ) || '',
        {
          ...baseOption,
          className: 'prixa-design-kit-toast ',
        }
      );
    }
  }

  const CloseButton = () => (
    <FontAwesomeIcon
      icon={iconList.faTimes}
      style={{ fontSize: '18px' }}
      className="margin-baseL"
    />
  );

  React.useEffect(() => {
    if (props.show) {
      notify();
    }
  }, [props.show]);

  return (
    <ToastContainerStyle
      className="prixa-design-kit-toast-container"
      position="top-center"
      autoClose={1500}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      closeButton={<CloseButton />}
    />
  );
};

export default Toast;
