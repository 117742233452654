import {
  layout,
  LayoutProps,
  color,
  ColorProps,
  fontWeight,
  FontWeightProps,
  typography,
  TypographyProps as StyledTypographyProps,
} from 'styled-system';

import { base } from '../../theme/configs/baseComponents';

export interface TypographyProps
  extends LayoutProps,
    ColorProps,
    FontWeightProps,
    StyledTypographyProps {
  /** Basic Attr */
  id?: string;
  color?: string;
  /** Custom Attr */
  fontWeight?: number;
}

const Typography = base.Text<TypographyProps>`
    ${layout}
    ${color}
    ${fontWeight}
    ${typography}
  `;

Typography.displayName = 'Typography';

export default Typography;
