import * as React from 'react';
import { css } from 'styled-components';
import { Link as LinkRoute } from 'react-router-dom';

import { styledWrapper as styled } from '../../theme/configs/baseComponents';
import { TypeScale } from '../../theme';
import { colors } from '../../theme/configs/baseVariable';

import { fontDimensions } from '../configs/fontDimensions';
import Typography, { TypographyProps } from './Typography';

export interface LinkProps extends TypographyProps {
  /** Basic Attr */
  children?: any;
  className?: string;
  style?: React.CSSProperties;
  /** Custom Attr */
  scale?: TypeScale;
  link?: string;
  href?: string;
  target?: string;
  onClick?: any;
  margin?: string | undefined;
  fontWeight?: number | undefined;
  fontSize?: string | undefined;
  color?: string | undefined;
  lineHeight?: string | undefined;
  fontStyle?: string | undefined;
}

export const LinkBehaviour = () => css`
  text-decoration: none;
  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover,
    &:active,
    &.active {
      opacity: 0.7;
      cursor: pointer;
    }
    &:focus,
    &.focus {
      outline: none;
    }
  }
  &:disabled,
  &.disabled {
    color: ${colors.disabled};
  }
`;

const StyledLink = styled(Typography)<LinkProps>`
  ${props => props.scale === 'body' && 'font-weight: 400'}
  ${LinkBehaviour}
`;

const Link: React.FunctionComponent<LinkProps> = (
  props: LinkProps
): React.ReactElement => {
  if (props.link) {
    return (
      <LinkRoute to={props.link || '/'} style={{ textDecoration: 'none' }}>
        <StyledLink
          {...fontDimensions(
            props.scale || 'content',
            undefined,
            props.margin || undefined,
            props.fontWeight || undefined,
            props.fontSize || undefined,
            props.color || undefined,
            props.lineHeight || undefined,
            props.fontStyle || undefined
          )}
          style={props.style}
          className={props.className}
        >
          {props.children}
        </StyledLink>
      </LinkRoute>
    );
  } else if (props.onClick && typeof props.onClick === 'function') {
    return (
      <div onClick={props.onClick} style={{ textDecoration: 'none' }}>
        <StyledLink
          {...fontDimensions(
            props.scale || 'content',
            undefined,
            props.margin || undefined,
            props.fontWeight || undefined,
            props.fontSize || undefined,
            props.color || undefined,
            props.lineHeight || undefined,
            props.fontStyle || undefined
          )}
          style={props.style}
          className={props.className}
        >
          {props.children}
        </StyledLink>
      </div>
    );
  } else {
    return (
      <a
        target={props.target || '_self'}
        href={props.href || '#'}
        style={{ textDecoration: 'none' }}
      >
        <StyledLink
          {...fontDimensions(
            props.scale || 'content',
            undefined,
            props.margin || undefined,
            props.fontWeight || undefined,
            props.fontSize || undefined,
            props.color || undefined,
            props.lineHeight || undefined,
            props.fontStyle || undefined
          )}
          style={props.style}
          className={props.className}
        >
          {props.children}
        </StyledLink>
      </a>
    );
  }
};

Link.defaultProps = {
  scale: 'content',
};

Link.displayName = 'Link';

export default Link;
