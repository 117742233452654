import * as React from 'react';
import { colors } from '../theme/configs/baseVariable';
import { Icon, IconSolid } from '../../src/icon';
import { SearchBarStyle } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export interface SearchBarProps {
  placeholder?: string;
  iconType?: string;
  disable?: boolean;
  inputVal?: string;
  setInputVal?: (inputVal: string) => void;
  className?: string;
  style?: object;
  width?: string;
  height?: number;
  autoFocus?: boolean;
  isLoading?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  iconType,
  disable,
  inputVal,
  setInputVal,
  className,
  style,
  width,
  height,
  autoFocus,
  isLoading,
}: SearchBarProps): React.ReactElement => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    {
      setInputVal && setInputVal(e.target.value);
    }
  };

  const clearInputVal = (): void => {
    {
      setInputVal && setInputVal('');
    }
  };

  return (
    <SearchBarStyle
      iconType={iconType}
      disable={disable}
      inputVal={inputVal}
      setInputVal={setInputVal}
      width={width}
      height={height}
    >
      <input
        type="text"
        placeholder={placeholder ? placeholder : 'Search'}
        disabled={disable ? disable : false}
        value={inputVal}
        onChange={handleInputChange}
        className={className || ''}
        style={style}
        autoFocus={autoFocus}
      />
      {iconType && (
        <Icon
          className="search-icon"
          type={iconType}
          width="16px"
          color={colors.black}
        />
      )}

      {isLoading ? (
        <FontAwesomeIcon
          className="loading-icon"
          icon={faCircleNotch}
          spin
          color={colors.black}
        />
      ) : (
        <div className="cancel-icon-box" onClick={clearInputVal}>
          <IconSolid
            className="cancel-icon"
            type="faTimes"
            width="16px"
            backgroundColor={colors.black}
          />
        </div>
      )}
    </SearchBarStyle>
  );
};

export default SearchBar;
