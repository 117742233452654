import * as React from 'react';
import styled from 'styled-components';
import { TextareaBaseProps, TextareaBase } from '../../styles';

export interface TextareaTextProps
  extends TextareaBaseProps,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  setData?: any;
  onClick?: any;
  disabledButton?: boolean;
  className?: any;
  errors?: any;
  disabled?: any;
  validate?: any;
  rows?: any;
}

const Input = styled('textarea')<TextareaTextProps>`
  ${TextareaBase};
  -webkit-appearance: none;
`;
const Textarea: React.FunctionComponent<TextareaTextProps> = (
  props: TextareaTextProps
): React.ReactElement => {
  const inputChangeHandle = (data: any) => {
    props.setData(data.target.value);
  };

  return (
    <Input
      className={props.className}
      errors={props.errors}
      disabled={props.disabled}
      validate={props.validate}
      onChange={e => inputChangeHandle(e)}
      rows={props.rows}
      // {...rest}
    />
  );
};

Textarea.defaultProps = {
  errors: false,
  validate: false,
  small: false,
};

Textarea.displayName = 'Textarea';

export default Textarea;
