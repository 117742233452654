import styled from 'styled-components';
import { colors, fonts } from '../theme/configs/baseVariable';
import { SearchBarProps } from './SearchBar';

export const SearchBarStyle = styled('div')<SearchBarProps>`
  width: ${(props): string => props.width || '335px'};
  height: ${(props): string => (props.height && `${props.height}px`) || '48px'};
  display: inline-block;
  box-sizing: border-box;
  border: none;
  position: relative;

  > input {
    box-sizing: inherit;
    width: 100%;
    height: 100%;
    border-radius: 48px;
    border: 1px solid ${colors.disabled};
    background: ${(props): string =>
      props.disable ? '#0000000d' : colors.white};
    color: ${(props): string =>
      props.disable ? colors.disabled : colors.black};
    font-size: 14px;
    font-family: ${fonts.sansSerif};
    padding: ${(props): string =>
      props.iconType ? '16px 48px' : '16px 48px 16px 16px'};
    outline: none;
    ${(props): undefined | false | string =>
      props.disable && `cursor: not-allowed;`}
  }

  > input::placeholder {
    font-style: italic;
    font-size: 12px;
    font-family: ${fonts.sansSerif};
    color: ${colors.disabled};
  }

  .search-icon {
    position: absolute;
    top: ${(props): string =>
      props.height ? `${props.height / 2 - 8}px` : '16px'};
    left: 16px;
  }

  .cancel-icon-box {
    display: ${(props): string => (props.inputVal ? 'inline-block' : 'none')};
    position: absolute;
    top: ${(props): string =>
      props.height ? `${props.height / 2 - 8}px` : '16px'};
    right: 16px;
    cursor: pointer;
  }

  .loading-icon {
    display: inline-block;
    position: absolute;
    top: ${(props): string =>
      props.height ? `${props.height / 2 - 8}px` : '16px'};
    right: 16px;
    cursor: pointer;
  }

  .cancel-icon {
    font-size: 12px;
  }
`;
