import * as React from 'react';
import styled from 'styled-components';

import { ButtonSize, ButtonType } from './configs/styles';
import { ButtonBaseProps } from './configs/utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export interface ButtonProps
  extends ButtonBaseProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Basic attr */
  id?: string;
  children?: any;
  className?: string;
  disabled?: boolean;
  link?: string;
  href?: string;
  target?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: any;
  loading?: boolean;
  style?: React.CSSProperties;
}

const Root = styled('button')<ButtonProps>`
  cursor: pointer;
  ${ButtonSize}
  ${ButtonType}
`;

const Button: React.FunctionComponent<ButtonProps> = (
  props: ButtonProps
): React.ReactElement => {
  if (props.link) {
    return (
      <Link to={props.link || '/'}>
        <Root
          className={props.className}
          disabled={props.disabled}
          style={props.style}
          size={props.size}
          padding={props.padding}
          color={props.color}
          width={props.width}
          variant={props.variant}
          type={props.type}
        >
          {props.children}
        </Root>
      </Link>
    );
  } else if (props.href) {
    return (
      <a target={props.target || '_self'} href={props.href}>
        <Root
          className={props.className}
          disabled={props.disabled}
          style={props.style}
          size={props.size}
          padding={props.padding}
          color={props.color}
          width={props.width}
          variant={props.variant}
          type={props.type}
        >
          {props.children}
        </Root>
      </a>
    );
  } else if (props.onClick && typeof props.onClick === 'function') {
    return (
      <Root
        className={props.className}
        disabled={props.disabled ? props.disabled : props.loading}
        style={props.style}
        size={props.size}
        padding={props.padding}
        color={props.color}
        width={props.width}
        variant={props.variant}
        type={props.type}
        onClick={props.onClick}
      >
        {!props.loading ? (
          ''
        ) : (
          <FontAwesomeIcon className="margin-tinyR" icon={faCircleNotch} spin />
        )}{' '}
        {props.children}
      </Root>
    );
  } else {
    return (
      <Root
        className={props.className}
        disabled={props.disabled}
        style={props.style}
        size={props.size}
        padding={props.padding}
        color={props.color}
        width={props.width}
        variant={props.variant}
        type={props.type}
      >
        {props.children}
      </Root>
    );
  }
};

Button.defaultProps = {
  variant: 'default',
  size: 'base',
};

Button.displayName = 'Button';

export default Button;
