import styled from 'styled-components';

export const SidesheetStyle = styled('div')`
  position: fixed;
  width: 100vw;
  top: 0;
  background: #ffffff;
  overflow: auto;
  z-index: 4;
  left: 0;
  bottom: 0;

  & > .prixa {
    margin: 69px auto 0;
    display: table;
  }
`;

export const SideSheetHeaderStyle = styled('div')`
  padding: 12px 0px;
  height: 40px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

export const SidesheetCaptionStyle = styled('div')`
  display: flex;
  flex: 1;
  font-weight: bold;
  justify-content: center;
`;

export const SidesheetImageCaptionStyle = styled('img')`
  width: 50.11px;
  height: 24px;
  margin-top: 3px;
`;

export const SidesheetMenubarStyle = styled('div')`
  margin-right: 15px;
  cursor: pointer;
`;
